<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/credit-score-monitoring-hero.jpg')"
      :mobileImage="
        require('@/assets/images/credit-score-monitoring-hero-mobile.jpg')
      "
      backgroundPosition="center center"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="5" offset-md="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <p class="hero-h1 font-weight-bolder mb-4">
                  24/7 Credit Monitoring
                </p>
                <p class="h4">
                  The best around-the-clock protection you can count on.
                </p>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                >
                  Get ScoreMaster<sup>&reg;</sup>
                </SignupLinkButton>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="py-3">
      <b-row class="mt-5 mb-6">
        <b-col cols="12" offset-xl="2" xl="8">
          <h2 class="h2 font-weight-bolder mb-5 text-center">
            Regularly Check and Protect Your Credit Score
          </h2>
          <p>
            From ID theft to data breaches, there are many things beyond your
            control that can damage your credit score. That’s why it’s never
            been more important to check your score on a regular basis and
            engage in a service that provides 24/7 monitoring.
          </p>
          <p>
            So, why is ScoreMaster<sup>&reg;</sup> the best solution for your
            credit score monitoring services? Because we offer more and do more
            than any other provider. We not only send you alerts for suspicious
            activity, payments due, and credit report events, but our $1 Million
            Whole Family Fraud Insurance covers your entire household.
          </p>
          <p>
            But that’s not all we do to keep you safe and private. Our unique
            PrivacyMaster<sup>&reg;</sup> feature scans and monitors websites
            that could be selling or misusing your personal information. Once
            located, you can request a removal of your personal data and prevent
            it from being sold or shared on these sites.
          </p>
          <p>
            In ScoreMaster<sup>&reg;</sup> you’ll see exactly how spending
            decreases your score so you learn how to balance the spending
            between your accounts. Plus, we have a personalized plan to help you
            get more points fast.
          </p>
          <p>
            When you gain a better score, it’s even more crucial to keep your
            points, and that’s what makes us the best credit score monitoring
            service available anywhere. Find out what our credit score
            monitoring can do for you, and try it today!
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" offset-xl="2" xl="8">
          <Accordion :accordionData="accordionData" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Accordion from '@/components/common/Accordion'

export default {
  name: 'BoostCreditScore',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  components: {
    Accordion,
  },
  data() {
    return {
      accordionData: [
        {
          title: 'What Is Credit Monitoring?',
          content:
            'Credit monitoring helps you catch mistakes or fraudulent activity, so you can immediately tackle any problems with your credit and minimize damage on your credit report. It also helps you check your progress and adjust accordingly.',
        },
        {
          title: 'Is Credit Score Monitoring Worth It?',
          content:
            'It’s not only worth it, but it’s a necessity if you want to stay on top of your score and even finances, in case you are the victim of identity fraud. Staying on top of your score is particularly important, since there are so many factors that can impact your situation.<br><br><strong>Just a few examples of why monitoring is more important than ever:</strong><ul><li>You’re already the victim of identity theft or at high risk of it.</li><li>Your social security number already has been disclosed in a data breach.</li><li>You’ve lost your Social Security card.</li><li>You don’t want to freeze your credit reports.</li><li>You have a great credit score and want to keep it there.</li><li>You’re working on getting a better score.</li></ul>',
        },
        {
          title: 'Does Credit Monitoring Affect Your Credit Score?',
          content:
            'Credit monitoring doesn’t affect your credit scores because it doesn’t require hard inquiries. If you access your own credit report, it’s considered a soft inquiry which doesn’t impact your score.',
        },
        {
          title: 'How Much Dose Credit Score Monitoring Cost?',
          content:
            'With ScoreMaster<sup>&reg;</sup>, it’s a regular part of your service. Visit the <a href="/consumer-signup/">Sign-up page</a> to get started today.',
        },
        {
          title: 'What Defines a Good Credit Score and Monitoring Service?',
          content:
            'The best credit score monitoring services alert you when your credit activity changes, like a newly opened account or a substantial purchase (e.g. car loan). And some offer more robust score tracking to help you stay on the right path. Ideally, your provider does both and that’s one of the many things that sets ScoreMaster<sup>&reg;</sup> apart.',
        },
        {
          title: 'Is It Bad to Regularly Check Your Score?',
          content:
            'No. In fact, quite the opposite is true. To begin with, credit scores aren’t impacted by checking your own credit reports or credit scores (soft inquires). On the contrary, regularly checking your credit reports and credit scores helps ensure your personal and account information is correct. It can also help detect signs of potential identity theft.',
        },
        {
          title: 'Beware of Scams',
          content:
            'The most important thing to remember? The only website authorized to provide free credit reports is AnnualCreditReport.com. So, beware of “imposter” websites that pretend to offer free credit reports but end up charging hidden fees or even selling your personal information, including your Social Security number.',
        },
        {
          title: 'Practice Good Credit Habits',
          content:
            'Remember, your credit score is never set in stone. Even if your score is good, you have to maintain best practices to keep it that way, like paying off your balances on time and in full and keeping a low credit utilization ratio.<br><br>If you have no credit history or bad credit history, checking your credit report and credit score is the best place to start. After all, you need absolute transparency to see where you stand and what improvements to make moving forward.',
        },
        {
          title: "What Credit Monitoring Doesn't Do",
          content:
            'While credit monitoring is important and helpful, it can’t stop all identity theft or unauthorized transactions. When you sign up for credit monitoring, you’ll receive alerts and resources that help identify and protect against possible theft, but these services can’t actually guarantee fraud prevention. At best, they keep you instantly informed so you can act as quickly as possible. And, with ScoreMaster<sup>&reg;</sup>, you’re insured in case such an event happens to you, or anyone in your household.<br><br>Here are several things monitoring your credit score doesn’t do:<ul><li>Stop someone from applying for credit and opening new accounts in your name.</li><li>Keep your information safe from data breaches.</li><li>Prevent your credit card from being skimmed.</li><li>Tell you if someone withdraws money from your bank account.</li><li>Warn you if someone files a tax return in your name and collects your refund.</li><li>Stop phishing emails.</li><li>Report fraud.</li><li>Fix credit report errors.</li><li>Freeze your credit.',
        },
        {
          title: 'Be Wary of Advertisements, Emails and Spam Phone Calls',
          content:
            'The best rule of thumb? If it sounds too good to be true, it probably is. So, when you get calls promising great deals or limited time offers that require you to act fast, it’s time to take a step back before you commit to anything. You never know if their intent is solely to get your information and use it maliciously.<br><br>Note the name of the company and person who placed the call and let them know you need more time to decide. Then research the company online to make sure they are legitimate and base your decision on what you find. And be sure you only go to sites that are “https” — with the “s” representing secure. But never, under any circumstance, should you share any personal information like your social security number on the initial call. That’s the only information a fraudster needs to do a great deal of damage to your financial profile.',
        },
      ],
    }
  },
}
</script>

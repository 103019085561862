<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/check-your-credit-score-hero.jpg')"
      :mobileImage="
        require('@/assets/images/check-your-credit-score-hero-mobile.jpg')
      "
      backgroundPosition="center center"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="5" offset-md="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <p class="hero-h1 font-weight-bolder mb-4">
                  Check Your Credit Score in a Snap!
                </p>
                <p class="h4">
                  ScoreMaster<sup>&reg;</sup> makes it simple and fast.
                </p>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                >
                  Get ScoreMaster<sup>&reg;</sup>
                </SignupLinkButton>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="py-3">
      <b-row class="mt-5">
        <b-col cols="12" offset-xl="2" xl="8">
          <h2 class="h2 font-weight-bolder mb-5 text-center">
            Keep Your Credit Score Error Free
          </h2>
          <p>
            With ScoreMaster<sup>&reg;</sup>, you get more than just a way to
            check your credit score. Our unique and patented credit score tools
            show you the following additional scores:
          </p>
          <ul>
            <li>
              Your Credit Score: based on your latest TransUnion credit report.
            </li>
            <li>
              Your Auto Score: over 90% of all Auto lenders require this when
              considering you for a loan.
            </li>
            <li>
              Your Insurance Score: may be used when you apply for any type of
              insurance.
            </li>
            <li>
              And your Hiring Risk Index: increasingly used by employers as part
              of their hiring/screening process.
            </li>
          </ul>
          <p>
            With that said, a regular credit score check is the best way to keep
            your information free of any errors. We show you how your score
            changes over time, while providing a side-by-side comparison of how
            your credit looks across all three major bureaus.
          </p>
          <p>
            Another way we help you stay on track, which no one else does, is
            our patent-pending PrivacyMaster<sup>&reg;</sup> feature, which
            scans and monitors websites that may be selling or misusing your
            personal information. Once we locate these sites, you can request a
            removal directly from your account, and prevent your information
            from being sold or shared.
          </p>
          <p class="mb-6">
            If checking your credit score is at the top of your priority list,
            ScoreMaster<sup>&reg;</sup> should be your first choice.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="text-center">
          <h2 class="h2 font-weight-bolder mb-4">
            Want Proof?
          </h2>
          <p>
            <strong>
              We’ll Let Other Customers Tell You What We’ve Done For Them
            </strong>
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-6">
        <b-col cols="12" lg="4">
          <Testimonial
            imgFile="testimonial-monique-g.png"
            imgAlt="Monique G. Testimonial"
            author="Monique G."
            testimony="Within 2 months my scores improved 60+ points."
          />
        </b-col>
        <b-col cols="12" lg="4">
          <Testimonial
            imgFile="testimonial-jose-b.png"
            imgAlt="Jose B. Testimonial"
            author="Jose B."
            testimony="ScoreMaster<sup>&reg;</sup> is the best! It's helped so much, lots
              of control, ease of use!"
          />
        </b-col>
        <b-col cols="12" lg="4">
          <Testimonial
            imgFile="testimonial-adam-c.png"
            imgAlt="Adam C. Testimonial"
            author="Adam C."
            testimony="ScoreMaster<sup>&reg;</sup> is an amazing tool! It tells you
              exactly what you need to do to raise your score."
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" offset-xl="2" xl="8">
          <Accordion :accordionData="accordionData" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Testimonial from '@/components/common/Testimonial'
import Accordion from '@/components/common/Accordion'

export default {
  name: 'MoreFeatures',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  components: {
    Testimonial,
    Accordion,
  },
  data() {
    return {
      accordionData: [
        {
          title: 'How Can I check My Credit Score?',
          content:
            'Easily through ScoreMaster<sup>&reg;</sup>. Sign up for our service and once your account is verified you will see your Vantage Score. If you need to see all three of your credit scores, you can purchase a 3B report inside of your account.',
        },
        {
          title:
            'How Many Times Can You Check Your Credit Before It Affects Your Score?',
          content:
            'Soft inquiries (like you just checking your credit through ScoreMaster<sup>&reg;</sup>) don’t affect your score at all. However, multiple hard inquiries can deplete your score by several points each time they happen.',
        },
        {
          title: 'Which Credit Score Should You Check?',
          content:
            'Always check your 3 Bureau credit scores to get an accurate picture of what your lenders will see. After all, you never know which score your prospective lender is going to pull.',
        },
        {
          title: 'How Are Credit Scores Calculated?',
          content:
            'Credit scores are calculated differently depending on the credit scoring model. But here are the key factors:<br><br><ul><li>Payment history: Whether you’ve paid past credit accounts on time.</li><li>Amounts owed: The total amount of credit and loans you’re using compared to your total credit limit, also known as your utilization rate.</li><li>Length of credit history: The length of time you’ve had credit.</li><li>New credit: How often you apply for and open new accounts.</li><li>Credit mix: The variety of credit products you have, including credit cards, installment loans, finance company accounts, mortgage loans and so on</li><li>Extremely influential: Payment history</li><li>Highly influential: Type and duration of credit and percent of credit limit used.</li><li>Moderately influential: Total balances/debt.</li><li>Less influential: Available credit and recent credit behavior and inquiries.</li>',
        },
        {
          title: "How It's Done on ScoreMaster<sup>&reg;</sup>",
          content:
            'The credit score changes shown in ScoreMaster<sup>&reg;</sup> typically apply to all credit scores the same, including FICO<sup>&reg;</sup> and VantageScore versions. That’s because all credit scores derive from TransUnion<sup>&reg;</sup>, Experian<sup>&trade;</sup> and Equifax<sup>&reg;</sup>, where your spending and payments are recorded.',
        },
        {
          title: "What Doesn't Impact Your Credit Score?",
          content:
            'The most common misconception is that your income or wealth impact your score. In fact, anything that defines how much money you have or how much you’re worth (e.g. income, retirement account balances, home equity, net worth), is not considered in your credit scores.<br><br>Other factors that don’t affect your credit score include:<br><br><ul><li>Race</li><li>Religion</li><li>Nationality</li><li>Gender</li><li>Marital status</li><li>Age</li><li>Political affiliation</li><li>Education</li><li>Occupation</li><li>Job title</li><li>Employer</li><li>Employment history</li><li>Where you live</li><li>Or your total assets',
        },
        {
          title:
            'Can You Use an Individual Taxpayer IDentification Number (ITIN) to Get Your Free Annual Credit Reports?',
          content:
            'Yes, you can use your ITIN if you submit your request to one of the three nationwide credit reporting companies by mail. Once the company receives your request, they’ll verify your identity.',
        },
        {
          title: 'What Are the Different Types of Credit Card?',
          content:
            'Generally, credit is organized into three major buckets:<br><br>1. Revolving credit: where a consumer borrows money from a lender and pays it back at the end, or makes partial monthly payments, as in a Visa or MasterCard charge card.<br><br>2. Charge credit: where the lender provides the consumer with a loan under the presumption that it is going to be paid in full at the end of the month, like on most American Express credit cards.<br><br>3. Installment credit: when the consumer agrees to finance a debt with monthly payments over a predetermined period of time, such as in a home mortgage or a car payment.',
        },
      ],
    }
  },
}
</script>

<template>
  <div>
    <FullWidthImageHero
      :image="require('@/assets/images/boost-credit-score-hero.jpg')"
      :mobileImage="
        require('@/assets/images/boost-credit-score-hero-mobile.jpg')
      "
      backgroundPosition="center center"
    >
      <b-container class="h-100">
        <b-row align-h="start" align-v="center" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="5" offset-md="5" offset-lg="6">
            <b-row>
              <b-col class="hero-text p-5 bg-white">
                <p class="hero-h1 font-weight-bolder mb-4">
                  The Best Way to Boost Your Credit Score
                </p>
                <p class="h4">
                  How high can it go?
                </p>
                <SignupLinkButton
                  :to="{ name: 'IndividualSignup' }"
                  pill
                  variant="secondary"
                  class="w-100 mt-4"
                >
                  Get ScoreMaster<sup>&reg;</sup>
                </SignupLinkButton>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </FullWidthImageHero>
    <b-container class="py-3">
      <b-row class="mt-5 mb-6">
        <b-col cols="12" offset-xl="2" xl="8">
          <h2 class="h2 font-weight-bolder mb-5 text-center">
            Take Your Credit Score to New Heights
          </h2>
          <p>
            If you’re like most people, you could probably use a credit score
            boost from time to time. ScoreMaster<sup>&reg;</sup> is your easy
            solution, with exclusive tools that help you add points to your
            score and keep those points.
          </p>
          <p>
            Our average user sees their credit score go up 61 points in 20 days
            or less. Not only that, but we also help safeguard those points with
            credit score monitoring and $1 Million Whole Family Fraud Insurance
            for your entire household.
          </p>
          <p>
            Additionally, we have a unique feature called PrivacyMaster<sup
              >&reg;</sup
            >
            that scans and monitors websites that may be selling or exploiting
            your personal information. Once we find these sites, you can request
            a removal of your data and prevent it from being sold or shared.
          </p>
          <p>
            Most importantly ScoreMaster<sup>&reg;</sup> gives you the borrowing
            potential you need to thrive, not just survive. And we even provide
            a customized plan to help you meet your goals at your pace.
          </p>
          <p>
            With easier access to loans and better rates, imagine the
            possibilities. From car loans to home financing, to new furniture,
            and getting approved for the best rewards credit cards,
            ScoreMaster<sup>&reg;</sup>
            is the credit score booster you’ve been looking for.
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" offset-xl="2" xl="8">
          <Accordion :accordionData="accordionData" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Accordion from '@/components/common/Accordion'

export default {
  name: 'BoostCreditScore',
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `https://www.scoremaster.com${this.$route.path}`,
        },
      ],
    }
  },
  mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
  components: {
    Accordion,
  },
  data() {
    return {
      accordionData: [
        {
          title: 'How to Boost Your Credit Score',
          content:
            "Wondering how to boost your credit score? These are the essential steps for getting on the right path:<br><br><ul><li><strong>Don’t Miss Payments</strong><br>Payment history has the biggest impact on your credit scores. As such, you want to avoid missing loan or credit card payments by more than 29 days—if you’re 30 or more days late, it can be reported to credit bureaus and hurt your credit scores.</li><br><li><strong>Catch Up on Past-Due Accounts</strong><br>Even though a late payment can remain on your credit report for up to seven years, keeping your accounts current can still positively impact your scores. This will also prevent more late payments and fees from being added to your credit history. </li><br><li><strong>Pay Down Revolving Account Balances</strong><br>Maintaining a low balance on credit cards and lines of credit can help you improve your scores. Use ScoreMaster<sup>&reg;</sup> to see the best times to pay your cards, which can add points to your score very fast. Not only that, see how spending on your credit cards can affect your score. </li><br><li><strong>Limit How Often You Apply for New Accounts</strong><br>Each application can lead to a hard inquiry, which may hurt your scores a little. Opening a new account also decreases the average age of your accounts which can also negatively impact your scores. </li><br>Wondering “how else can I boost my credit score?” Find out how we <a href='/consumer-credit-report-benefits/'>give real people real results.</a></ul>",
        },
        {
          title: 'Establishing or Building Your Credit Scores',
          content:
            'How you approach this depends on who is providing/creating your score:<br><br><ul><li>FICO<sup>&reg;</sup> Scores: you need at least one account that’s six months old or older and credit activity during the past six months.</li><br><li>VantageScore: score may be calculated as soon as an account appears on your report. When you don’t meet the criteria, the scoring model can’t score your credit report—in other words, you’re “credit invisible.” And being “credit invisible” means creditors can’t check your credit scores, making it harder to open new credit accounts.</li></ul>',
        },
        {
          title: 'How Fast Does Your Credit Score Go Up After Paying Debt?',
          content:
            'It can take as long as two months for debt payment information to be reflected on your credit score. This has to do with both the timing of credit card and loan billing cycles and the monthly reporting process followed by lenders.',
        },
        {
          title: 'Why Did My Credit Score Drop After Paying Debt?',
          content:
            '1. You eliminated your only installment loan or revolving debt: Creditors like to see that you’re able to manage various types of debt. For example, if you pay off an auto loan and are left with only credit cards, your credit mix suffers. This is not a good way to boost your credit score.<br><br>2. You increased your overall credit utilization: when you pay off a revolving line of credit in its entirety and close the account, it decreases the total amount of available credit, potentially increasing your remaining utilization rate. If you’re looking for a credit booster, avoid this. You can certainly pay off a revolving line of credit, but you are better off keeping it open.<br><br>3. You lowered the average age of your accounts: The longer your accounts have been open and in good standing, the better.',
        },
        {
          title: 'How Credit Card Utilization Affects Your Score',
          content:
            'What is credit card utilization? It just means how much of your credit limit you use at any given time.<br><br>What’s the best way to keep it under control? Just pay your credit card balances in full each month. Of course, you may not always have the means to do that. When that happens, try keeping your total outstanding balance at 30 percent or less of your total credit limit. Then you can work on reducing it to 10 percent or less, which is considered ideal for an optimal credit score.',
        },
        {
          title: 'How Utilization Ratio Is Calculated',
          content:
            'Let’s say you have two credit cards. Card A has a $6,000 credit limit and a $2,500 balance. Card B has a $10,000 limit and you have a $1,000 balance on it.<br><br>This is your utilization ratio per card:<br><br><ul><li>Card A = 42% (2,500/6,000 = .416, or 42%), which is too high.</li><br><li>Card B = 10% (1,000/10,000 = .100, or 10%), which is awesome.</li><br><li>This is your overall credit utilization ratio: 22% (3,500/16,000 = 0.218), which is very good.</li>',
        },
        {
          title: "Why It's Good to Keep Old Accounts Open",
          content:
            'It may seem counterintuitive, but if you have old credit accounts you’re not using, keep them open. Here’s why: closing credit cards while you have a balance on other cards lowers your available credit and increases your credit utilization ratio. That could knock a few points off your score.',
        },
        {
          title: "Ignoring Deliquencies Doesn't Make Them Go Away",
          content:
            'Delinquent accounts, charge-offs, or collection accounts cannot be ignored. Start with a plan to catch up on past due amounts, then arrange to make timely future payments. While this won’t erase late payments, it can improve your payment history in the future. For charge-offs or collection accounts, either pay them in full or try settling with the creditor.<br><br>But if you’re thinking about how to boost your credit score, paying off collections should be one of the things you focus on.<br><br>Remember, credit score changes shown on ScoreMaster<sup>&reg;</sup> typically apply to all credit scores the same, including the FICO<sup>&reg;</sup> and VantageScore versions. That’s because all credit scores are derived from Transunion<sup>&reg;</sup>, Experian<sup>&trade;</sup> and Equifax<sup>&reg;</sup> where your payments and spending are reported.',
        },
      ],
    }
  },
}
</script>
